<template>
  <div class="sock">
    <!-- 132 -->
    <el-button type="primary" @click="open">通电</el-button>
    <el-button type="primary" @click="close">断电</el-button>
    <div class="empty"></div>
    <el-card class="box-card">
      <el-form inline>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="当前状态:">
              <el-tag type="success">{{ scok.switchingState }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="累计用量:">
              <el-tag type="success">{{ scok.electricityConsumption }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="剩余电量:">
              <el-tag type="success">{{ scok.electricityRemaining }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="当前电压:">
              <el-tag type="success">{{ scok.voltage }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前功率:">
              <el-tag type="success">{{ scok.power }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前电流:">
              <el-tag type="success">{{ scok.electricCurrent }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="环境温度:">
              <el-tag type="success">{{ scok.roomTemperature }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="限定功率:">
              <el-tag type="success">{{ scok.limitedPower }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="限定电流:">
              <el-tag type="success">{{ scok.limitingCurrent }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    
  </div>
</template>

<script>
export default {
  props: {
    sock: {
      type: Object,
    },
    id: {
      type: Number | String,
    },
    es: {
      type: Number | String,
    },
  },
  // 累计电量:功率:电压:电流:功率因数:
  data() {
    return {
      scok: {},
      issocketShow:false,
    };
  },
  deactivated() {
    console.log("2222");
  },
  mounted() {
    console.log(this.id);
  },
  destroyed() {
    console.log(this.timer);
  },
  watch: {
    es: {
      handler: function (val) {
        console.log(val);
        if (val) {
          this.getkind();
          this.timer = setInterval(() => {
            this.getkind();
          }, 3000);
        } else {
          clearInterval(this.timer);
        }
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.$axios({
        method: "post",
        url: "/common2/devices/operate",
        data: {
          devId: this.id,
          devState: "开",
        },
      })
        .then((res) => {
          this.$message.success("控制成功");
          this.issocketShow=true
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    close() {
      this.$axios({
        method: "post",
        url: "/common2/devices/operate",
        data: {
          devId: this.id,
          devState: "关",
        },
      })
        .then((res) => {
          this.$message.success("控制成功");
          this.issocketShow=false
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getkind() {
      console.log(this.es);
      this.$axios({
        method: "get",
        // url: `/common2/devices/getBatchState`,
        url: `/common2/devices/getDevicesState`,
        params: {
          ids: this.id,
        },
      }).then((res) => {
        console.log(res.data.result[0]);
        this.scok = res.data.result[0];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  height: 20px;
}
.airConditioner .airHeader p{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin:10px 0px;
}
.airConditioner .airHeader .wendu{
    text-align: center;
    font-size: 70px;
    color: #63cf50;
    font-weight: bold
}
.airConditioner .airHeader span{
    margin:0px 50px;
}
.airConditioner .airHeader svg{
    vertical-align: middle;
} 
.airConditioner .airHeader .p{
    color: #9b9c9d;
    margin: 0px 0px;
}
.airConditioner .button{
    text-align: center;
    margin: 10px 0px;
}
.airConditioner .button .el-button{
    background: #63cf50;
    text-align: center;
    // font-weight: bold;
    margin: 0px 10px;
    border:0px;
    font-size: 18px;
}
.airSelect{
    // border: 1px solid red;
    text-align: center;
}
.airSelect .el-button{
    background: #eaeaea;
    color: #9b9c9d;
    // font-weight: bold;
    border: 0px;
    font-size: 18px;
    margin: 10px 20px;
}
.swButton{
    text-align: center;
}
.swButton .el-button{
    background: #63cf50;
    margin: 20px 10px;
    border:0px;
    font-size: 14px;
    width:100px;
}
.SWSelect{
    text-align: center;
}
.SWSelect .el-button{
    background: #eaeaea;
    color: #9b9c9d;
    border: 0px;
    font-size: 14px;
    margin: 0px 10px;
    width:100px;
    margin-bottom: 20px;
}
</style>